export const en_US = {
  toolbar: {
    reward: "Rewards",
    batch: "Batches",
    roadmap: "Roadmap",
    mynft: 'My NFTs',
    home: 'Home'
  },
  home: {
    title: 'Join this great experience and be part of Banana Club',
    subtitle: 'Create, participate & earn various rewards.'
  },
  combinations: 'Combinations',
  rewards: {
    title: 'Receive rewards in different ways:',
    list1: 'Draws for each mining batch',
    list2: 'Airdrops according to rarity',
    list3: 'Bonus for new collections',
    list4: 'And many more to come',
  },
  batch: {
    text1: 'The mint runs in 4 Batches. Upon reaching the goal of each Batch, a raffle will be held in which there will be great prizes.',
    text2: 'The community will vote for the Token they want for the Airdrop. Those creditors of a Banana Club NFT will be able to vote.',
    text3: '1 Banana Club NFT = 1 Vote',
    info: 'Distribution table',
    titlePrize: 'Places to award',
    amount: 'Quantity'
  },
  table: {
    mint: 'Mint',
    price: 'Price Mint',
    draw2: 'Lottery & Airdrops',
    draw: 'Lottery',
    notes: '*The value of the prize may vary according to the price of BNB that is held at the time of the draw.',
    notes2: 'The airdrop token will be selected by the community'
  },
  button: {
    mint: 'Mint now',
    soldOut: 'Sold Out'
  },
  mint: {
    title: 'Mint Banana Club NFT',
    wl: 'Congratulations! You are part of the White List.'
  },
  nft: {
    nonft: 'You still do not have any Banana Club NFT.'
  },
  notification: {
    success: 'Tx success. Click here to see the transaction',
    network: 'Connect your wallet. wrong network',
    network2: 'Wrong network, connect to network '
  }
};
