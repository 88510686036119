import { SimpleGrid, GridItem, Button } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { changePage } from "../app/counter";

function Pagination({ full = true }) {
    const dispatch = useAppDispatch();
    const { pages, page } = useAppSelector((state) => state.counter);
    const selectPage = (pageSelected: number) => {
        if(pageSelected + 1 === page) return
        dispatch(changePage(pageSelected));
    }
    const changesPage = (i: number) => {
        let n = i + page;
        if(n > pages) return
        if(n <= 0) n = 1;
        dispatch(changePage(n-1));
    }
    return (
        <SimpleGrid columns={1} width="100%" paddingX={"20px"} marginBottom="20px">
            <GridItem>
            <Button
                variant="outline"
                size="sm"
                borderColor="blue.800"
                color="blue.500"
                fontSize="15px"
                fontWeight="normal"
                px={2}
                height="36px"
                _hover={{
                  borderColor: "blue.300",
                  border: "2px"
                }}
                background=" white"
                borderRadius= "5px 0px 0px 5px"
                width={"80px"}
                onClick={() => changesPage(-1)}
              >
                Previuos
              </Button>
              {
                [...Array(pages)].map((m, index) => (
                    <Button
                    key={`btn-${index}`}
                variant="outline"
                size="sm"
                borderColor="blue.800"
                color="blue.500"
                fontSize="15px"
                fontWeight="normal"
                px={2}
                height="36px"
                width="36px"
                _hover={{
                  borderColor: "blue.300",
                  border: "2px"
                }}
                background=" white"
                borderRadius= "0px"
                borderLeft={"0px"}
                className={page === (index+1) ? 'btn-active' : ''}
                onClick={() => selectPage(index)}
              >
                {index + 1}
              </Button>
                ))
              }
              <Button
                variant="outline"
                size="sm"
                borderColor="blue.800"
                color="blue.500"
                fontSize="15px"
                fontWeight="normal"
                px={2}
                height="36px"
                width={"80px"}
                borderLeft="0px"
                _hover={{
                  borderColor: "blue.300",
                  border: "2px"
                }}
                background=" white"
                borderRadius= "0px 5px 5px 0px"
                onClick={() => changesPage(1)}
              >
                Next
              </Button>
            </GridItem>
        </SimpleGrid>

    );
}

export default Pagination;
