import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { useContractCall, useContractFunction } from "@usedapp/core";
import ABI_CONTRACT from "../data/contract.json";
import { MINT_ADDRESS } from "../data/addresses";

const contractInterface = new ethers.utils.Interface(ABI_CONTRACT);
const contract = new Contract(MINT_ADDRESS, contractInterface);

export function useContractFunctions(method: string, args: any = []) {

    try {
        const [res] = useContractCall({
            abi: contractInterface,
            address: MINT_ADDRESS,
            method: method,
            args: args,
        }) ?? [];
        return res;
    } catch (error) {
        return null;
    }

}


export function useExecuteFunctions(method: string) {
    const { state, send } = useContractFunction(contract, method, {});
    return { state, send };
}