import { SimpleGrid, GridItem, Box, Image, Stack, Text, Link } from '@chakra-ui/react'
import { useEthers } from '@usedapp/core';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getNfts } from '../app/counter';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import LoadingImg from "../assets/images/loading.jpg";
import { useContractFunctions } from '../hooks';
import Pagination from './Pagination';
import EmptyImg from "../assets/images/empty.png";


const NftList: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch();
    const { account } = useEthers();

    const { lastUpdate, data } = useAppSelector((state) => state.counter);
    const ownerIds = useContractFunctions('walletOfOwner', [account]);
    useEffect(() => {
        const now = Math.floor(Date.now() / 1000);
        const diff = now - (lastUpdate ?? 0);
        if (ownerIds && diff > 600) dispatch(getNfts(ownerIds));
    }, [dispatch, ownerIds, lastUpdate]);
    return (
        <>
            <SimpleGrid paddingX={"20px"} columns={[1, 9, 12, 15]} spacing={10} width="100%" marginTop="130px">
                {
                    data.length === 0 ? (
                        <GridItem colStart={[1, 2, 3, 4]} colSpan={[6, 7, 8, 9]}>
                            <div style={{ fontSize: "20px", textAlign: "center" }}>
                                {t('nft.nonft')}
                            </div>
                            <Box boxShadow={"2xl"} w={"full"} rounded={"md"}
                                overflow={"hidden"}
                                border={`5px solid 'gray'`}
                                position="relative"
                                display={"flex"}
                                justifyContent="center"
                            >

                                <Image
                                    w={"full"}
                                    src={EmptyImg}
                                    objectFit={"cover"}
                                    borderRadius={"15px"}
                                    width="350px"
                                />

                            </Box>
                        </GridItem>
                    ) : (<>
                        <GridItem className='algo' colStart={[2, 2, 3, 4]} colSpan={[6, 7, 8, 9]}>
                        <Pagination />
                        <SimpleGrid paddingX={"20px"} columns={[1, 1, 12, 16]} spacing={10} width="100%">
                        {
                            data.map((nft, index) => (
                                <GridItem
                                    key={`mynft-${nft.nftId}`}
                                    colSpan={[1, 1, 4, 4]}
                                    textAlign="center"
                                >
                                    <Box
                                        maxW={"350px"}
                                        w={"full"}
                                        bg={"white"}
                                        boxShadow={"2xl"}
                                        rounded={"md"}
                                        overflow={"hidden"}
                                        border={`5px solid 'gray'`}
                                        position="relative"
                                    >
                                        <Box key={`${index}-${nft.thumbPath}`}>
                                            <Link fontWeight="700" href={`/detail/${nft.nftId}`}>


                                                <Image
                                                    w={"full"}
                                                    src={nft.thumbPath ? `${nft.thumbPath}?img-size=150&img-quality=50` : LoadingImg}
                                                    objectFit={"cover"}
                                                    borderRadius={"0px"}
                                                    cursor="pointer"
                                                />
                                            </Link>
                                            <Box p={6}>
                                                <Stack direction={"row"} justify={"center"} spacing={10}>
                                                    <Stack spacing={0} align={"center"}>
                                                        <Text fontWeight={600} fontSize={14}>
                                                            {nft.name}
                                                        </Text>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Box>
                                </GridItem>
                            ))
                        }
                        </SimpleGrid>
                        </GridItem>
                    </>)
                }
            </SimpleGrid>
        </>
    )
}

export default React.memo(NftList)
