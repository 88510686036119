import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const URL = process.env.REACT_APP_API_URL;
const URL_SCAN = process.env.REACT_APP_URL_SCAN;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

interface Attribute {
  trait_type: string;
  value: string;
}
interface Nft {
  attributes: Attribute[];
  nftId: number;
  image: string;
  name: string;
  thumbPath: string;
  loading?: boolean;
  status?: number;
}
interface CounterState {
  value: number;
  nfts: Nft[];
  loading: boolean;
  lastUpdate?: number;
  pages: number;
  page: number;
  data: Nft[];
  perPage: number;
  detail?: Nft;
  urlScan?: string;
  chainId?: string;
}

// Define the initial state using that type
const initialState: CounterState = {
  value: 0,
  nfts: [],
  loading: false,
  pages: 1,
  page: 1,
  data: [],
  perPage: 15,
  urlScan: URL_SCAN,
  chainId: CHAIN_ID
};

export const counterSlice = createSlice({
  name: "counter",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLastUpdate: (state, action: PayloadAction<number>) => {
      state.lastUpdate = action.payload;
    },
    setPagination: (state, action: PayloadAction<number>) => {
      const a = [...state.nfts];
      state.data = a.splice(action.payload * state.perPage, state.perPage);
      state.page = action.payload + 1;
    },
    setDetail: (state, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
    setNfts: (state, action: PayloadAction<any>) => {
      state.nfts = action.payload;
      state.pages = Math.ceil(state.nfts.length / state.perPage)
      const a = [...state.nfts];
      state.data = a.splice(state.page - 1, state.perPage);
    },
  },
});

export const { setLoading, setNfts, setLastUpdate, setPagination, setDetail } = counterSlice.actions;

export const changePage = (page: number) => async (dispatch: any) => {
  dispatch(setPagination(page));
}

export const getNfts = (ids: [number]) => async (dispatch: any) => {
  dispatch(setLoading(true));
  const data = await Promise.all(
    ids?.map(async (id, index) => {
      const nftData = await dispatch(mappingNfts(+id));
      return {id: index, ...nftData};
    })
  );
  const now = Math.floor(Date.now() / 1000);
  dispatch(setLastUpdate(now))
  dispatch(setNfts(data));
  dispatch(setLoading(false));
};

export const mappingNfts = (id: number) => async (dispatch: any) => {
  try {
    const response = await fetch(`${URL}/bclub/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const getDetailNft = (id: string, nfts: any[]) => async(dispatch: any) => {

  dispatch(setLoading(true));
  let nft = nfts.find(n => n.nftId === +id);
  if(!nft) nft = await dispatch(mappingNfts(+id));
  dispatch(setDetail(nft));
  dispatch(setLoading(false));
  
}

export default counterSlice.reducer;
