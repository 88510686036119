import {
    SimpleGrid,
    Image,
    Text,
    Box,
    GridItem,
} from '@chakra-ui/react';
import Layout from '../components/Layout';
import Toolbar from '../components/Toolbar';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getDetailNft } from '../app/counter';
import { useEffect } from 'react';
import NotFoundImg from "../assets/images/404.png";
import LoadingImg from "../assets/images/loading.jpg";

type ParamsDetail = {
    id: string
}
export default function DetailNft() {
    const { id } = useParams<ParamsDetail>();
    const { nfts, detail } = useAppSelector((state) => state.counter);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getDetailNft(id, nfts));
    }, [dispatch, id, nfts]);
    return (
        <>
            <Layout>
                <Toolbar />
                <SimpleGrid columns={[10, 6, 10, 10]} width="100%" spacing={10} marginTop="130px">
                    {
                        (!detail || detail?.status === 404) && 
                        <GridItem colStart={[2, 2, 3, 3]} colSpan={[6, 2, 6, 6]}>
                            <Box boxShadow={"2xl"} w={"full"} rounded={"md"}
                                overflow={"hidden"}
                                border={`5px solid 'gray'`}
                                position="relative"
                                display={"flex"}
                                justifyContent="center"
                            >
                                <Image
                                    w={"full"}
                                    src={NotFoundImg}
                                    objectFit={"cover"}
                                    borderRadius={"15px"}
                                    width="350px"
                                />

                            </Box>
                        </GridItem>
                    }

                    { detail?.name  && 
                    <GridItem className="quepasa" colStart={[2, 2, 3, 3]} colSpan={[8, 4, 6, 6]}>
                        <Box boxShadow={"dark-lg"} w={"full"} rounded={"md"}
                            overflow={"hidden"}
                            border={`5px solid 'gray'`}
                            position="relative">
                            <SimpleGrid justifyContent="center" columns={[10, 6, 10, 10]} width="100%" padding={[5,5,16,16]} marginTop="10px" display={["flex","flex","none","none"]}>
                            <GridItem
                                    colSpan={[10, 6, 10, 10]}
                                    justifyContent="center"
                                >
                                    <Box
                                        maxW={"350px"}
                                        w={"full"}
                                        boxShadow={"2xl"}
                                        rounded={"md"}
                                        overflow={"hidden"}
                                        border={`5px solid 'gray'`}
                                        position="relative"
                                    >
                                        <Image
                                            w={"full"}
                                            src={detail.thumbPath ?? LoadingImg}
                                            objectFit={"cover"}
                                            borderRadius={"15px"}
                                        />
                                    </Box>
                                </GridItem>
                            </SimpleGrid>
                            <SimpleGrid columns={[10, 6, 10, 10]} width="100%" padding={[5,5,16,16]} marginTop={["0px","0px","10px","10px"]}>
                                <GridItem colSpan={[10, 6, 5, 5]}>
                                    <Box>
                                        <Text
                                            display={["flex", "flex", "flex", "flex"]}
                                            fontSize={[18, 20, 22, 26]}
                                            fontWeight="900"
                                            color="principal"
                                        >
                                            {detail.name}
                                        </Text>
                                        <Text fontSize={[14, 16, 18, 18]} color="secondaryComplementario" marginY={5}>
                                            Token ID {detail.nftId}
                                        </Text>
                                    </Box>
                                    <Box fontFamily="League Gothic" fontSize="20px">
                                        {
                                            detail.attributes.map( (at) => (
                                                <SimpleGrid columns={[2,2,3,3]} key={at.trait_type}>
                                                    <Box>{at.trait_type}</Box>
                                                    <Box color={"principal"}>{at.value}</Box>
                                                </SimpleGrid>
                                            ))
                                        }
                                    </Box>
                                </GridItem>
                                <GridItem
                                    marginLeft={["0px", "0px", "10px", "10px"]}
                                    colSpan={[5, 3, 5, 5]}
                                    fontSize={[12, 14, 16, 16]}
                                    display={["none","none","flex","flex"]}
                                    justifyContent="center"
                                >
                                    <Image
                                            w={"full"}
                                            boxShadow={"2xl"}
                                            rounded={"md"}
                                            src={detail.thumbPath ?? LoadingImg}
                                            objectFit={"cover"}
                                        />
                                </GridItem>
                            </SimpleGrid>
                        </Box>
                    </GridItem> }

                </SimpleGrid>
            </Layout>
        </>
    );
}