import {
    Box,
    Button,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Alert,
    AlertIcon,
    Link,
} from "@chakra-ui/react";
import { useEthers, useNotifications } from "@usedapp/core";
import { BigNumber, ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { useContractFunctions, useExecuteFunctions } from "../hooks";

type Props = {
    isOpen: any;
    onClose: any;
};

export default function MintModal({ isOpen, onClose }: Props) {
    const { account, chainId: chainIdEthers } = useEthers();
    const { notifications } = useNotifications();
    const { t } = useTranslation();
    const minAmount = 1;
    // const maxAmount = 10;
    const [amountMint, setAmountMint] = useState(1);
    const [maxMintAmount, setMaxMintAmount] = useState(10);

    const batchNumber = useContractFunctions('batch');
    const maxSupply = useContractFunctions('maxSupply');
    const totalSupply = useContractFunctions('totalSupply');
    const costs = useContractFunctions('costs', [batchNumber ?? 0]);
    const limit = useContractFunctions('limits', [batchNumber ?? 0]);
    //whitelisted
    const wlCosts = useContractFunctions('wlCosts', [batchNumber ?? 0]);
    const wlCount = useContractFunctions('wlCount', [batchNumber ?? 0]);
    const wlLimits = useContractFunctions('wlLimits', [batchNumber ?? 0]);
    const maxWLMint = useContractFunctions('maxWLMint');
    const isWhiteListed = useContractFunctions('isWhitelisted', [account]);
    const addressWLMintedBalance = useContractFunctions('addressWLMintedBalance', [account]);

    const [supplyPerBatch, setSupplyPerBatch] = useState(totalSupply ?? 0);
    const [supplyPerWL, setSupplyPerWL] = useState(wlCount ?? 0);
    const [maxMintWLAmount, setMaxMintWLAmount] = useState(maxWLMint ?? 4);
    const [isFinishedWL, setIsFinishedWL] = useState(false);
    
    let allLimits: number[] = Array(4);
    allLimits[0] = useContractFunctions('limits', [0]);
    allLimits[1] = useContractFunctions('limits', [1]);
    allLimits[2] = useContractFunctions('limits', [2]);
    allLimits[3] = useContractFunctions('limits', [3]);
    const price = formatEther(costs ?? 0);
    const priceWL = formatEther(wlCosts ?? 0);
    const [priceMint, setPriceMint] = useState(price);
    const [priceWLMint, setPriceWLMint] = useState(priceWL);
    const { urlScan, chainId } = useAppSelector((state) => state.counter);

    //variable finished WL
    const { state, send: buyBClub } = useExecuteFunctions('mint');
    useEffect(() => {
        setSupplyPerBatch(totalSupply);
        const diff = +limit - +totalSupply;
        if (diff < 10) {
            setMaxMintAmount(diff);
        }
        if (batchNumber > 0 && +totalSupply > allLimits[batchNumber - 1]) {
            const newSupply = totalSupply - allLimits[batchNumber - 1];
            setSupplyPerBatch(newSupply);
        }
        //WL
        const diffMintWL = +maxWLMint - +addressWLMintedBalance;
        if (isWhiteListed && !isFinishedWL) setMaxMintWLAmount(diffMintWL);
        const diffWL = +wlLimits - +wlCount;
        if (isWhiteListed && !isFinishedWL && +diffWL < +maxWLMint) {
            // let d = +diffWL;
            // const diffTxWL = +diffWL > 
            setMaxMintWLAmount(diffWL);
        }
        if (batchNumber > 0 && +wlCount > wlLimits) {
            const newSupply = wlCount - wlLimits;
            setSupplyPerWL(newSupply);
        }
        // const diffSupply = 
    }, [limit, totalSupply, allLimits, batchNumber, wlLimits, wlCount, maxWLMint, addressWLMintedBalance, isWhiteListed, isFinishedWL]);

    const changeAmount = (amount: number) => {
        let newAmount = amountMint + amount;
        if (newAmount <= minAmount) {
            newAmount = minAmount;
        }
        if (newAmount >= maxMintAmount) {
            newAmount = maxMintAmount
        }
        if(isWhiteListed && !isFinishedWL && newAmount >= maxMintWLAmount) newAmount = +maxMintWLAmount;

        setAmountMint(newAmount);

        const p = BigNumber.from(costs);
        const n = p.mul(newAmount);
        setPriceMint(formatEther(n.toString()));
        if (isWhiteListed) {
            const cWL = BigNumber.from(wlCosts);
            const nWL = cWL.mul(newAmount);
            setPriceWLMint(formatEther(nWL.toString()));
        }
    }

    const mint = async () => {
        const myPrice = isWhiteListed && !isFinishedWL ? priceWLMint : priceMint;
        try {
            buyBClub(amountMint, {
                value: ethers.utils.parseEther((myPrice).toString()),
            })
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (costs) {
            const p = BigNumber.from(costs);
            const n = p.mul(amountMint);
            setPriceMint(formatEther(n.toString()));
        }
    }, [costs, price, amountMint]);
    
    useEffect(() => {
        if (wlCosts) {
            const cWL = BigNumber.from(wlCosts);
            const nWL = cWL.mul(amountMint);
            setPriceWLMint(formatEther(nWL.toString()));
        }
    }, [wlCosts, price, amountMint]);

    useEffect(() => {
        notifications.map(n => {
            if (n.type === 'transactionSucceed') {
                changeAmount(-30);
            }
            return 1;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);
    useEffect(() => {
        setIsFinishedWL(false)
        if(isWhiteListed && (+addressWLMintedBalance >= +maxWLMint || +wlCount >= +wlLimits)) setIsFinishedWL(true)
    }, [isWhiteListed, addressWLMintedBalance, maxWLMint, wlCount, wlLimits])
    useEffect(() => {
        setMaxMintWLAmount(maxWLMint);
    },[maxWLMint])
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
            <ModalOverlay />
            <ModalContent
                background="gray.900"
                border="1px"
                borderStyle="solid"
                borderColor="gray.700"
                borderRadius="3xl"
            >
                <ModalHeader color="white" px={4} fontSize="lg" fontWeight="medium">
                    {t('mint.title')}
                </ModalHeader>
                <ModalCloseButton
                    color="white"
                    fontSize="sm"
                    _hover={{
                        color: "whiteAlpha.700",
                    }}
                />
                <ModalBody pt={0} px={4}>
                    {account && (chainIdEthers && chainId && (chainIdEthers === +chainId)) ?
                        <Box
                            borderRadius="3xl"
                            border="1px"
                            borderStyle="solid"
                            borderColor="gray.600"
                            px={5}
                            pt={4}
                            pb={2}
                            mb={3}
                        >
                            {
                                isWhiteListed && !isFinishedWL &&
                                <Flex justifyContent="center" alignItems="center">
                                    <Text color="white" fontSize="20px" fontFamily='League Gothic'>
                                        {t('mint.wl')}
                                    </Text>
                                </Flex>
                            }
                            <Flex justifyContent="center" alignItems="center" mb={3}>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    borderColor="blue.800"
                                    borderRadius="10px"
                                    color="blue.500"
                                    fontSize="24px"
                                    fontWeight="normal"
                                    px={2}
                                    height="36px"
                                    _hover={{
                                        background: "none",
                                        borderColor: "blue.300",
                                    }}
                                    onClick={() => changeAmount(-1)}
                                >
                                    -
                                </Button>
                                <Text color="white" fontSize="40px" marginX={'20px'} fontFamily='League Gothic'>
                                    {amountMint}
                                </Text>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    borderColor="blue.800"
                                    borderRadius="10px"
                                    color="blue.500"
                                    fontSize="24px"
                                    fontWeight="normal"
                                    px={2}
                                    height="36px"
                                    _hover={{
                                        background: "none",
                                        borderColor: "blue.300",
                                    }}
                                    onClick={() => changeAmount(1)}
                                >
                                    +
                                </Button>
                            </Flex>
                            {
                                isWhiteListed && !isFinishedWL &&
                                <Flex justifyContent="center" alignItems="center">
                                    <Text color="white" fontSize="32px" fontFamily='League Gothic'>
                                        Total: {priceWLMint ? priceWLMint.toString() : '----'} BNB
                                    </Text>
                                </Flex>
                            }

                            <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="28px" fontFamily='League Gothic' textDecoration={isWhiteListed && !isFinishedWL ? 'line-through' : ''}>
                                    Total: {priceMint ? priceMint.toString() : '----'} BNB
                                </Text>
                            </Flex>
                            <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="20px" fontFamily='League Gothic'>
                                    Batch: {+batchNumber + 1}
                                </Text>
                            </Flex>
                            <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="20px" fontFamily='League Gothic'>
                                    Total Supply Batch: {supplyPerBatch !== undefined && limit ? `${supplyPerBatch} / ${limit}` : '---/---'}
                                </Text>
                            </Flex>
                            <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="20px" fontFamily='League Gothic'>
                                    Total Supply: {totalSupply && maxSupply ? `${totalSupply} / ${maxSupply}` : '---/---'}
                                </Text>
                            </Flex>
                            {
                                isWhiteListed && !isFinishedWL ? 
                                <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="20px" fontFamily='League Gothic'>
                                    Maximum Limit WL: {maxMintWLAmount ? maxMintWLAmount.toString() : '----'} Banana Club NFTs
                                </Text>
                            </Flex>
                            :
                            <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="20px" fontFamily='League Gothic'>
                                    Maximum per TX: {maxMintAmount} Banana Club NFTs
                                </Text>
                            </Flex>
                            }
                            {
                                isWhiteListed && !isFinishedWL ? 
                                <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="20px" fontFamily='League Gothic'>
                                    Mint Price: {wlCosts ? formatEther(wlCosts) : '----'} BNB
                                </Text>
                            </Flex>
                            :
                                <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="20px" fontFamily='League Gothic'>
                                    Mint Price: {costs ? formatEther(costs) : '----'} BNB
                                </Text>
                            </Flex>
                            }
                            <Flex justifyContent="center" alignItems="center" mt={3}>
                                <Button
                                    size="sm"
                                    borderColor="blue.800"
                                    borderRadius="10px"
                                    color="blue.500"
                                    fontSize="30px"
                                    fontWeight="normal"
                                    px={2}
                                    height="36px"
                                    width="80px"
                                    fontFamily='League Gothic'
                                    _hover={{
                                        background: "blue.800",
                                        borderColor: "blue.300",
                                    }}
                                    onClick={mint}
                                    isLoading={state.status.toLowerCase() === 'Mining'.toLowerCase() || state.status.toLowerCase() === 'PendingSignature'.toLowerCase()}
                                >
                                    Mint
                                </Button>
                            </Flex>
                        </Box>
                        :
                        <Box
                            borderRadius="3xl"
                            border="1px"
                            borderStyle="solid"
                            borderColor="gray.600"
                            px={5}
                            pt={4}
                            pb={2}
                            mb={3}
                        >
                            <Flex justifyContent="center" alignItems="center">
                                <Text color="white" fontSize="28px" fontFamily='League Gothic'>
                                    {t('notification.network')}
                                </Text>
                            </Flex>
                        </Box>
                    }
                </ModalBody>

                <ModalFooter
                    justifyContent="end"
                    background="gray.700"
                    borderBottomLeftRadius="3xl"
                    borderBottomRightRadius="3xl"
                    p={6}
                >
                    <Text
                        color="white"
                        textAlign="left"
                        fontWeight="medium"
                        fontSize="md"
                    >
                        {notifications.map((notification) => (
                            notification.type === 'transactionSucceed' && (
                                <Alert status='success'>
                                    <AlertIcon />
                                    <Link href={`${urlScan}/tx/${notification.transaction.hash}`} isExternal color="principal" fontFamily="League Gothic" fontSize={18}>
                                        {t('notification.success')}
                                    </Link>
                                </Alert>
                            )
                        ))}
                    </Text>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}