import Layout from "../components/Layout";
import "@fontsource/inter";
import ToolBar from "../components/Toolbar";
import NtfList from "../components/NtfList";
import { useMemo } from "react";

const MyNft = () => {
    const memoizedCard = useMemo(() => {
        return <NtfList />;
    }, []);
    return (
        <>
            <Layout>
                <ToolBar />
                {memoizedCard}
            </Layout>

        </>
    );
};

export default MyNft;
