import {
    Box,
    Button,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { useEthers } from "@usedapp/core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { useContractFunctions } from "../hooks";
import MintModal from "./MintModal";

export default function Mint() {
    const { t } = useTranslation();
    const { account, chainId: chainIdEthers } = useEthers();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const batchNumber = useContractFunctions('batch');
    const maxSupply = useContractFunctions('maxSupply');
    const totalSupply = useContractFunctions('totalSupply');
    const { chainId } = useAppSelector((state) => state.counter);
    return (
        <>
            <MintModal isOpen={isOpen} onClose={onClose} />
            {!account ?
                <Box paddingTop={"30px"} display={["grid", "grid", null, null]} justifyContent={["center", "center", "left", "left"]}>
                    <Text
                        display={["flex", "flex", "flex", "flex"]}
                        fontSize="26px"
                        fontFamily="League Gothic"
                    >
                        Connect to wallet
                    </Text>
                </Box>
                :
                chainId === undefined || (chainIdEthers && chainId && (chainIdEthers !== +chainId)) ?  
                <Box paddingTop={"30px"} display={["grid", "grid", null, null]} justifyContent={["center", "center", "left", "left"]}>
                    <Text
                        display={["flex", "flex", "flex", "flex"]}
                        fontSize="26px"
                        fontFamily="League Gothic"
                    >
                        {t('notification.network2')}{chainId}
                    </Text>
                </Box>
                :
                <Box paddingTop={"30px"} display={["grid", "grid", null, null]} justifyContent={["center", "center", "left", "left"]}>
                    <Text
                        display={["flex", "flex", "flex", "flex"]}
                        fontSize="26px"
                        fontFamily="League Gothic"
                    >
                        Batch {batchNumber ? +batchNumber.toString() + 1 : '---'}
                    </Text>
                    <Text
                        display={["flex", "flex", "flex", "flex"]}
                        fontSize="26px"
                        fontFamily="League Gothic"
                    >
                        Minted: {totalSupply && maxSupply ? `${totalSupply} / ${maxSupply}` : '---/---'}
                    </Text>
                    <Button
                        background="blue.900"
                        color="blue.100"
                        fontWeight="normal"
                        fontSize="xl"
                        _hover={{
                            textDecoration: "none",
                            color: "whiteAlpha.800",
                            background: "blue.600"
                        }}
                        onClick={onOpen}
                        disabled={(totalSupply && maxSupply) && +totalSupply >= +maxSupply}
                    >
                        {totalSupply && maxSupply && +totalSupply >= +maxSupply && t('button.soldOut')}
                        {totalSupply && maxSupply && +totalSupply < +maxSupply && t('button.mint')}
                    </Button>
                </Box>
            }
        </>
    );
}
