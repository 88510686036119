import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Config, DAppProvider } from "@usedapp/core";
import "./App.scss";
import { Provider } from "react-redux";
import { store } from "./app/store";
import './internationalization/i18n';

const config: Config = {
  notifications: {
    expirationPeriod: 30000,
    checkInterval: 0
  },
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
