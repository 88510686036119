import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import theme from "./theme";
import "@fontsource/inter";
import Home from "./pages/Home";
import * as dotenv from 'dotenv';
import MyNft from "./pages/MyNft";
import DetailNft from "./pages/DetailNft";

dotenv.config();

function App() {

  return (
    <>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/mynft" exact>
            <MyNft />
          </Route>
          <Route path="/detail/:id" exact>
            <DetailNft />
          </Route>
          <Redirect to="/" />
          
        </Switch>
        </BrowserRouter>
      </ChakraProvider>
    </>

  );
}

export default App;
